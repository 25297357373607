import fetch from 'cross-fetch';

import VRM from '@motorway/lib-vrm-node';

import { replaceWhitespace, stringNumberToInt } from 'Utilities/formatter';
import { VRM_REGEX } from 'Utilities/helpers';

import { platformPost } from '../services/platformEndpoints';

export const INTENT = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
});

// Helper functions
export const getFieldState = (meta, validateOnInit) => {
  const showState = (validateOnInit || meta.touched) && !meta.active && !meta.validating;
  if (showState && meta.invalid) {
    return INTENT.ERROR;
  }
  if (showState && meta.valid) {
    return INTENT.SUCCESS;
  }

  return null;
};

/**
 * This helper function should be used to replace `getFieldState` as it supports handling submission errors.
 * Once older fields are depreciated we can remove `getFieldState` altogether.
 */
export const getFieldStateWithSubmissionErrors = (meta, validateOnInit) => {
  const showState = (validateOnInit || meta.touched) && !meta.active && !meta.validating;
  const isValidationOrSubmissionError = (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit));
  if (showState && isValidationOrSubmissionError) {
    return INTENT.ERROR;
  }
  if (showState && !isValidationOrSubmissionError) {
    return INTENT.SUCCESS;
  }

  return undefined;
};

export const composeValidators = (...validators) => (value) => validators
  .reduce((error, validator) => error || validator(value), undefined);

const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (...args) => {
    const [first] = args;
    if (first !== lastArg) {
      lastArg = first;
      lastResult = fn(...args);
    }
    return lastResult;
  };
};

// Validation functions
const emailValidate = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return re.test(value);
};

const platformEmailValidate = simpleMemoize(async (value, user) => platformPost('/email-check', {
  email: value,
  userId: user ? user.id : null,
}));

const platformPhoneValidate = simpleMemoize(async (value, qs) => fetch(`/api/phone-number-validation/${value}${qs}`).then((res) => res.json()));

export const requiredValidator = (message) => (value) => (value ? undefined : message || 'Required');

export const noBlankValidator = (message) => (value) => (value && value.trim() !== '' ? undefined : message || 'Required');

export const emailValidator = (message) => (value) => (emailValidate(value) ? undefined : message || 'Email invalid');

export const platformEmailValidator = (errorMessage, existMessage, user) => async (val) => {
  let platformResponse = { message: '', valid: true };
  try {
    platformResponse = await platformEmailValidate(val, user);
  } catch (error) {
    window?.Sentry?.captureException(new Error(error));
  }
  const { message, valid } = platformResponse;
  if (message === 'already_exists') {
    return existMessage;
  }
  return valid ? undefined : errorMessage || 'Email invalid';
};

export const phoneNumberValidator = (message, isMobileOnly = false) => async (val) => {
  const errorMessage = message || 'Phone number invalid';
  if (!val || val.match(/\//g)) {
    return errorMessage;
  }
  let platformResponse = { valid: true };
  try {
    platformResponse = await platformPhoneValidate(val, isMobileOnly ? '?mobileOnly=true' : '');
  } catch (error) {
    window?.Sentry?.captureException(new Error(error));
  }
  const { valid } = platformResponse;
  return valid ? undefined : errorMessage;
};

export const postCodeValidator = (message) => (val) => {
  const re = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/i;
  const match = val?.match(re);
  if (!match || val !== match[0]) {
    return message || 'Postcode invalid';
  }
  return undefined;
};

export const numberValidator = (message) => (val) => {
  const numberRegex = /^\d+$/;
  if (!val || !numberRegex.test(val)) {
    return message || 'Number invalid';
  }
  return undefined;
};

export const numberCommaSeperatedValidator = (message) => (val) => {
  if (!val || Number.isNaN(stringNumberToInt(val))) {
    return message;
  }
  return undefined;
};

export const checkMotMileage = (message, mileageLastMot) => (val) => {
  if (!mileageLastMot || !val) {
    return undefined;
  }

  if (stringNumberToInt(val) < mileageLastMot) {
    return message;
  }

  return undefined;
};

export const checkHighMileage = (message, {
  bypassCheck,
  last_mot_date: lastTestDate,
  mileageLastMot,
  year,
}) => (mileage) => {
  // The keys BE is sending https://github.com/motorway/motorway-platform/blob/master/app/api/general/handlers/vehicleHandlers.js#L43-L47
  if (bypassCheck || !lastTestDate || !mileageLastMot) {
    return undefined;
  }

  // High mileage check
  const val = stringNumberToInt(mileage);

  const maxMilesPerYear = 30000;
  const maxMilesPerDay = maxMilesPerYear / 365;

  const vehicleYear = year;
  const currentYear = (new Date()).getFullYear();
  const diff = currentYear - vehicleYear;

  const checkMileageIsNotTooHigh = (mileDiff, dayDiff) => (mileDiff / dayDiff <= maxMilesPerDay)
    || (dayDiff <= 30 && mileDiff <= 1000);

  const currentTime = (new Date()).getTime();
  const dayMS = (1000 * 60 * 60 * 24);

  if (!lastTestDate) {
    if (diff <= 1) {
      // A year or less difference, try to calculate from beginning of year
      const startTime = (new Date((new Date()).getFullYear() - diff, 0, 0)).getTime();
      const dayDiff = (currentTime - startTime) / dayMS;

      if (!checkMileageIsNotTooHigh(val, dayDiff)) {
        return message;
      }
    } else if (!checkMileageIsNotTooHigh(val, diff)) {
      return message;
    }
  } else {
    // Use lastTestDate, no more than 82 miles per day diff
    const lastTestTime = (new Date(lastTestDate)).getTime();

    const dayDiff = (currentTime - lastTestTime) / dayMS;
    const mileDiff = val - mileageLastMot;

    if (!checkMileageIsNotTooHigh(mileDiff, dayDiff)) {
      return message;
    }
  }

  return undefined;
};

export const unprettyVRM = (vrm) => vrm.replace(/\s/g, ''); // VRM regex won't accept whitespace
export const isValidVRM = (vrm) => VRM_REGEX.test(unprettyVRM(vrm));
export const coerceVRM = (vrm) => VRM.coerce(vrm)?.[0]?.prettyVrm || vrm;
export const stripSpaces = (str) => str.toUpperCase().replace(/[^A-Z0-9\s]/, '');

export const VRMValidator = (message) => (val) => {
  const valid = isValidVRM(val);
  if (!valid) {
    return message || 'VRM invalid';
  }
  return undefined;
};

export const verificationCodeValidator = (message) => (value) => {
  const formattedValue = replaceWhitespace(value);
  if (formattedValue.length !== 6) {
    return message || 'invalid verification code';
  }
  return undefined;
};
